<template>
  <div class="pay-service-wrap animate__animated animate__fadeInLeftBig">
    <div class="content">
      <div class="wrap">
        <div
          class="animate__animated animate__fadeInLeftBig"
          v-if="stepDetail === 1"
        >
          <div class="title">选择带货类目</div>
          <div class="cate-wrap">
            <div
              class="cate-item"
              v-for="(name, id) in cates"
              :key="id"
              :class="{ active: selCate === id }"
              @click="selectCate(id)"
            >
              {{ name }}
            </div>
          </div>
        </div>
        <div
          class="animate__animated animate__fadeIn"
          v-else-if="stepDetail === 2"
        >
          <div class="title">了解服务</div>
          <div class="table-header">
            <div class="table-header-item">服务项目</div>
            <div class="table-header-item">市场价值</div>
          </div>
          <div class="table-body">
            <div class="table-body-item">直播间设计搭建+软件服务</div>
            <div class="table-body-item">1000</div>
          </div>
          <div class="table-header">
            <div class="table-header-item">赠送项目</div>
            <div class="table-header-item">市场价值</div>
          </div>
          <div class="table-body">
            <div class="table-body-item">子弹头配件-需邮寄</div>
            <div class="table-body-item">60</div>
            <div class="table-body-item">1000粉丝涨粉扶持</div>
            <div class="table-body-item">240</div>
            <div class="table-body-item">90小时带货讲解声音</div>
            <div class="table-body-item">2250</div>
          </div>
          <div class="pricc-wrap">
            <div class="label">特惠</div>
            <div class="price"><span class="prefix">¥</span>150</div>
            <div class="price-original"><span class="prefix">¥</span>3550</div>
          </div>
        </div>
        <div v-else-if="stepDetail === 3">
          <div class="result">
            <img src="@/assets/img/color-success.svg" alt="" class="icon" />
            <div class="text-wrap">
              <div class="text-1">支付成功</div>
              <div class="text-tip">请填写实际收货地址，会尽快为您寄送配件</div>
            </div>
          </div>
          <div class="product-wrap">
            <img
              src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/recording_tool.png"
              alt=""
            />
            <div class="name">子弹头配件</div>
          </div>
          <div class="address-wrap">
            <van-address-edit
              :detail-rows="2"
              :detail-maxlength="400"
              @save="saveAddress"
              save-button-text="领取配件"
              :area-list="areaList"
              :address-info="initAddress"
            />
          </div>
        </div>
        <div
          class="animate__animated animate__fadeIn"
          v-else-if="stepDetail === 4"
        >
          <div class="title">了解直播设备</div>
          <div class="product-wrap">
            <img
              src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/phone.png"
              alt=""
            />
            <div class="name">酷派Coolpad 20</div>
          </div>
          <div class="remark">
            <p>1.开播后，手机基本是24小时直播，平时用不了这部手机</p>
            <p>2.推荐用【酷派Coolpad 20】4GB+64GB 版本</p>
            <p>3.自行购买，京东官网 699，拼多多568</p>
            <p>
              4.或自备手机，建议安卓10.0以上的系统，全面屏手机。(不保证开播后的稳定性)
            </p>
          </div>
        </div>
      </div>
      <div class="tip-text-wrap">
        <template v-if="stepDetail === 1">
          <div class="tip-title">注意事项</div>
          <div class="text">1. 只能选择一个</div>
          <div class="text">2. 将根据选择的类目，搭建对应风格的直播间</div>
        </template>
        <template v-else-if="stepDetail === 2">
          <div class="tip-title">注意事项</div>
          <div class="text">
            1. 子弹头是必备配件，开播后插在手机上防止杂音进到直播间
          </div>
          <div class="text">
            2. 90小时带货讲解声音，可使用1个月。1个月后，仍然可继续免费使用
          </div>
        </template>
      </div>
    </div>
    <div class="next-button animate__animated animate__backInUp">
      <van-button
        type="info"
        block
        @click="createOrder"
        v-if="stepDetail === 2"
        :disabled="!canPay"
        >立即购买（¥150）</van-button
      >
      <van-button
        v-else-if="stepDetail !== 3"
        type="info"
        block
        :disabled="disableNext"
        @click="next"
        >下一步</van-button
      >
    </div>
  </div>
</template>
<script>
//可按需导入
import { mapMutations, mapState } from "vuex";
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      cates: [],
      selCate: "",
      stepDetail: 1,
      canPay: true,
      name: null,
      phone: null,
      addressDetail: null,
      areaList,
      province: "",
      city: "",
      county: "",
    };
  },
  computed: {
    ...mapState({
      stage: (state) => state.accountInfo && state.accountInfo.stage,
      accountInfo: (state) => state.accountInfo,
      isWeixin: (state) => state.isWeixin,
    }),
    disableNext() {
      if (this.stepDetail === 1 && !this.selCate) {
        return true;
      }
      return false;
    },
    initAddress() {
      let temp = {
        name: this.name,
        tel: this.phone,
        province: this.province,
        city: this.city,
        county: this.county,
        areaCode: "",
        addressDetail: this.addressDetail,
        isDefault: true,
      };
      let proviceCode = "";
      for (let key in areaList.province_list) {
        if (areaList.province_list[key] === this.province) {
          proviceCode = key;
          break;
        }
      }
      let cityCode = "";
      for (let key in areaList.city_list) {
        if (areaList.city_list[key] === this.city && key > proviceCode) {
          cityCode = key;
          break;
        }
      }
      for (let key in areaList.county_list) {
        if (areaList.county_list[key] === this.county && key > cityCode) {
          temp.areaCode = key;
          break;
        }
      }
      return temp;
    },
  },
  watch: {
    stage: {
      immediate: true,
      handler() {
        switch (this.stage) {
          case "s2_no_huopan":
            this.stepDetail = 1;
            break;
          case "s2_order":
            this.stepDetail = 2;
            break;
          case "s2_no_address":
            this.stepDetail = 3;
            if (this.isWeixin) {
              wx.openAddress({
                success: (res) => {
                  console.log(">>>> openAddress", res);
                  this.name = res.userName;
                  this.phone = res.telNumber;
                  this.province = res.provinceName;
                  this.city = res.cityName;
                  this.county = res.countryName;
                  this.addressDetail = res.detailInfo;
                },
              });
            }

            break;
          case "s3_device":
            this.stepDetail = 4;
            break;
          default:
            return;
        }
      },
    },
  },
  created() {
    this.fetchCate();
  },
  methods: {
    ...mapMutations(["setCurrentPid"]),
    async fetchCate() {
      let ret = await this.$http.get(`/available-huopans`);
      this.cates = ret;
    },
    selectCate(id) {
      this.selCate = id;
    },
    async next() {
      if (this.stepDetail == 1) {
        let ret = await this.$http.put(
          `/devices/${this.accountInfo.id}/bind-huopan`,
          {
            huopan_id: this.selCate,
          }
        );
        this.setCurrentPid(
          Object.assign({}, this.accountInfo, { stage: ret.stage })
        );
      } else if (this.stepDetail == 4) {
        await this.$http.put(`/devices/${this.accountInfo.id}/stage/s4_fans`);
        this.setCurrentPid(
          Object.assign({}, this.accountInfo, { stage: "s4_fans" })
        );
      }
    },
    async createOrder() {
      if (!this.canPay) return;
      this.canPay = false;
      let ret = await this.$http.post(
        `/devices/${this.accountInfo.id}/cashier-pay-orders`,
        {
          return_url: location.href,
        }
      );
      location.href = ret.url;
      this.canPay = true;
    },
    async saveAddress(address) {
      console.log(">>>> save address", address);
      await this.$http.put(`/devices/${this.accountInfo.id}/set-address`, {
        name: address.name,
        phone: address.tel,
        zone: `${address.province} ${address.city} ${address.county}`,
        address: address.addressDetail,
      });
      this.stepDetail = 4;
    },
  },
};
</script>
<style lang="scss" scoped>
.pay-service-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .content {
    width: 100%;
    height: 100%;
    font-size: r(26);
    padding-top: r(40);
    padding-left: r(60);
    padding-right: r(60);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: r(40);
      margin-bottom: r(40);
    }
    .tip-text-wrap {
      font-size: r(26);
      line-height: r(40);
      .tip-title {
        color: #ee0a24;
        margin-bottom: r(10);
      }
      .text {
        margin-bottom: r(10);
      }
    }
    .cate-wrap {
      display: flex;
      flex-wrap: wrap;
      .cate-item {
        font-size: r(30);
        padding: r(20) r(40);
        border: r(1) solid #dcdfe6;
        color: #606266;
        border-radius: r(8);
        margin-right: r(20);
        margin-bottom: r(20);
        &.active {
          background: #1989fa;
          color: #fff;
          border-color: #409eff;
        }
      }
    }
    .table-header {
      font-size: r(30);
      display: flex;
      align-items: center;
      background: #606266;
      color: #fff;
      width: 100%;
      border: r(1) solid #ddd;
      .table-header-item {
        box-sizing: border-box;
        padding: r(20) 0;
        &:first-child {
          width: 60%;
          padding-left: r(30);
          border-right: r(1) solid #ddd;
        }
        &:nth-child(2) {
          width: 40%;
          text-align: center;
        }
      }
    }
    .table-body {
      font-size: r(26);
      display: flex;
      align-items: center;
      color: #000;
      width: 100%;
      border: r(1) solid #ddd;
      border-top: none;
      margin-bottom: r(60);
      flex-wrap: wrap;
      border-bottom: none;
      .table-body-item {
        box-sizing: border-box;
        padding: r(20) 0;
        border-bottom: r(1) solid #ddd;
        &:nth-child(odd) {
          width: 60%;
          padding-left: r(30);
          border-right: r(1) solid #ddd;
          flex-shrink: 0;
        }
        &:nth-child(even) {
          width: 40%;
          text-align: center;
          flex-shrink: 0;
        }
      }
    }
    .pricc-wrap {
      margin-top: r(40);
      display: flex;
      justify-content: center;
      font-size: r(36);
      align-items: flex-end;
      .label {
        margin-right: r(40);
      }
      .price {
        margin-right: r(20);
        .prefix {
          font-size: r(26);
        }
      }
      .price-original {
        font-size: r(26);
        color: #ee0a24;
        text-decoration: line-through;
      }
    }
    .result {
      display: flex;
      align-items: center;
      .icon {
        width: r(100);
        height: r(100);
        margin-right: r(20);
      }
      .text-wrap {
        font-size: r(40);
        .text-tip {
          font-size: r(26);
          color: #606266;
          margin-top: r(20);
        }
      }
    }
    .address-wrap {
      margin-top: r(40);
      :deep(.van-button--danger) {
        color: #fff;
        background-color: #1989fa;
        border: 1px solid #1989fa;
      }
    }
    .product-wrap {
      margin: 0 auto;
      margin-top: r(60);
      border: r(1) solid #ddd;
      padding: r(10);
      display: flex;
      border-radius: r(8);
      img {
        width: r(200);
      }
      .name {
        padding-top: r(60);
      }
    }
    .remark {
      margin-top: r(60);
      font-size: r(30);
      line-height: r(45);
      p {
        margin-bottom: r(20);
      }
    }
  }
  .next-button {
    flex-shrink: 0;
    padding: r(20) r(40) r(60) r(40);
    :deep(.van-button) {
      border-radius: 22px;
    }
  }
}
</style>