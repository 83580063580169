<template>
  <div class="fans-shop-wrap">
    <div class="content animate__animated animate__fadeInLeftBig">
      <div v-if="stepDetail === 1">
        <div class="title">涨粉、发布视频作品</div>
        <div class="tip">作品 ≥ 10，粉丝 ≥ 1000，才能开通带货橱窗</div>
        <div class="cicle-wrap">
          <div>
            <van-circle
              v-model="fCnt"
              :rate="fans / 10"
              :speed="100"
              layer-color="#ebedf0"
              :text="fanText"
            />
            <div class="label">粉丝数：{{ fans }}/1000</div>
          </div>
          <div>
            <van-circle
              v-model="vCnt"
              :rate="videosCnt * 10"
              :speed="100"
              layer-color="#ebedf0"
              :text="videoText"
            />
            <div class="label">作品：{{ videosCnt }}/10</div>
          </div>
        </div>
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item title="如何快速制作带货短视频" name="1">
            <video
              controls
              controlslist="nodownload"
              src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/quick_make_short_video.mp4"
              poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/quick_make_short_video_poster.jpg"
            ></video>
          </van-collapse-item>
          <van-collapse-item title="小于50粉，手动先涨到50粉" name="2">
            <video
              controls
              controlslist="nodownload"
              src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/gain_follow.mp4"
              poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/gain_follow_poster.png"
            ></video>
          </van-collapse-item>
          <div class="fans-up-wrap">
            <div class="sub-title">大于50粉，涨粉太慢，联系导师扶持</div>
            <img src="@/assets/img/kefu.png" alt="" class="kefu" />
          </div>
        </van-collapse>
      </div>
      <div
        class="animate__animated animate__fadeIn"
        v-else-if="stepDetail === 2"
      >
        <div class="title">开通橱窗</div>
        <div class="step-wrap">
          <div class="step-title">第一步：找到电视带货</div>
          <div class="text">点击抖音【我的】-【三道杠】-【创作者中心】</div>
          <div class="text">在【全部】里找到【电商带货】</div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_up_storefront_1.png"
            alt=""
          />
        </div>
        <div class="step-wrap">
          <div class="step-title">第二步：实名认证</div>
          <div class="text">点击【加入电商带货】-选择【个人】</div>
          <div class="text">上传身份，提交审核</div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_up_storefront_2.png"
            alt=""
          />
        </div>
        <div class="step-wrap">
          <div class="step-title">第三步：绑定银行卡</div>
          <div class="text">填写银行卡信息，提交审核</div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_up_storefront_3.png"
            alt=""
          />
        </div>
        <div class="step-wrap">
          <div class="step-title">第四步：缴纳保证金</div>
          <div class="text">点击【作者保证金】,充值带货保证金</div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_up_storefront_4.png"
            alt=""
          />
        </div>
        <div class="step-wrap">
          <div class="step-title">第五步：微信签约</div>
          <div class="text">当微信账号的状态，从"审核中"，变成"去签约"，</div>
          <div class="text">
            点击【去签约】-使用微信扫描二维码，完成签约授权
          </div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_up_storefront_5.png"
            alt=""
          />
        </div>
        <div class="step-wrap">
          <div class="step-title">第6步：开通成功</div>
          <div class="text">
            当我的页面，出现【商品橱窗】入口，恭喜你，橱窗已经开通完车了
          </div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_up_storefront_6.png"
            alt=""
          />
        </div>
      </div>
      <template v-else-if="stepDetail === 3">
        <div key="3" class="animate__animated animate__fadeIn">
          <div class="title">绑定公会</div>
          <div class="step-wrap" @click="showTip">
            <div class="step-title">
              1.当前分佣比例{{ ratio }},了解详情<van-icon name="info-o" />
            </div>
          </div>
          <div class="step-wrap">
            <div class="step-title">2.添加导师微信</div>
            <div class="text">告知对方，已开通橱窗，需要协助【绑定工会】</div>
            <div class="kefu-wrap">
              <img src="@/assets/img/kefu.png" alt="" class="kefu" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="next-button animate__animated animate__backInUp">
      <van-button
        v-if="stepDetail === 3"
        type="info"
        block
        disabled
        @click="next"
        >绑定公会后，会进入下一步</van-button
      >
      <van-button v-else type="info" block :disabled="disableNext" @click="next"
        >下一步</van-button
      >
    </div>
    <!--分佣比例介绍--->
    <van-dialog
      v-model="showTipFlag"
      :show-cancel-button="false"
      :showConfirmButton="false"
      :closeOnClickOverlay="true"
    >
      <div class="agent-wrap">
        <div class="title">【佣金】</div>
        <p>达人开播后，售卖成交的商品，会产生佣金</p>
        <div class="title">【分佣比例】</div>
        <p>达人的分成比例，根据达人的抖音号，直播间每天的订单金额而定</p>
        <div class="ratio-wrap">
          <div class="item">
            <div class="sub-title">【一档】</div>
            <div class="text-wrap">
              <p>每天订单金额，0-5000。</p>
              <p>5/5分成，达人拿5成</p>
            </div>
          </div>
          <div class="item">
            <div class="sub-title">【二档】</div>
            <div class="text-wrap">
              <p>每天订单金额，5000-2万。</p>
              <p>4/6分成，达人拿6成</p>
            </div>
          </div>
          <div class="item">
            <div class="sub-title">【三档】</div>
            <div class="text-wrap">
              <p>每天订单金额，大于2万</p>
              <p>3/7分成，达人拿7成</p>
            </div>
          </div>
        </div>
        <div class="title">【结算打款】</div>
        <p>1.达人抖音号与小竹猫传媒公会绑定后。</p>
        <p>2.达人的佣金，和公会的佣金，是抖音分开结算的。</p>
        <p>3.达人的佣金，抖音结算后完成后，会直接打到达人的抖音账户。</p>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      stepDetail: 1,
      fCnt: 0,
      vCnt: 0,
      activeName: 0,
      ratio: "50%",
      showTipFlag: false,
    };
  },
  computed: {
    ...mapState({
      stage: (state) => state.accountInfo && state.accountInfo.stage,
      accountInfo: (state) => state.accountInfo,
      fans: (state) => {
        if (state.accountInfo && state.accountInfo.extra) {
          return state.accountInfo.extra.fansCnt;
        } else {
          return 0;
        }
      },
      videosCnt: (state) => {
        if (state.accountInfo && state.accountInfo.extra) {
          return state.accountInfo.extra.videosCnt;
        } else {
          return 0;
        }
      },
      ratio: (state) => {
        if (state.accountInfo && state.accountInfo.cos_ratio) {
          return `${state.accountInfo.cos_ratio * 100}%`;
        } else {
          return "50%";
        }
      },
    }),
    disableNext() {
      if (
        this.stepDetail === 1 &&
        (this.fansCnt < 1000 || this.videosCnt < 10)
      ) {
        return true;
      }
      return false;
    },
    fanText() {
      let str = "";
      str = Math.floor(this.fans / 10);
      return str + "%";
    },
    videoText() {
      let str = "";
      str = Math.floor(this.videosCnt * 10);
      return str + "%";
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    stage: {
      immediate: true,
      handler() {
        switch (this.stage) {
          case "s4_fans":
            this.stepDetail = 1;
            break;
          case "s5_shop":
            this.stepDetail = 2;
            break;
          case "s6_guild_binding":
            this.stepDetail = 3;
            break;
          default:
            return;
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setCurrentPid"]),
    async fetch() {
      if (this.accountInfo && this.accountInfo.id) {
        let ret = await this.$http.get(`/devices/${this.accountInfo.id}`);
        this.setCurrentPid(ret);
      }
    },
    async next() {
      let stage = this.stage;
      switch (this.stage) {
        case "s4_fans":
          stage = "s5_shop";
          break;
        case "s5_shop":
          stage = "s6_guild_binding";
          break;
        default:
          return;
      }
      this.setCurrentPid(Object.assign({}, this.accountInfo, { stage }));
    },
    showTip() {
      this.showTipFlag = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.fans-shop-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .content {
    width: 100%;
    font-size: r(26);
    padding-top: r(40);
    padding-left: r(60);
    padding-right: r(60);
    overflow-y: auto;
    flex-shrink: 0;
    flex: 1;
    .title {
      font-size: r(40);
      margin-bottom: r(40);
    }
    .tip {
      font-size: r(26);
      margin-top: r(40);
    }
    .cicle-wrap {
      margin-top: r(80);
      margin-bottom: r(80);
      display: flex;
      align-items: center;
      justify-content: space-around;
      .label {
        margin-top: r(40);
        text-align: center;
      }
    }
    .fans-up-wrap {
      padding: 10px 16px 0;
      .sub-title {
        font-size: 14px;
        line-height: 24px;
      }
      .kefu {
        width: r(400);
        display: block;
        margin: r(40) auto 0;
      }
    }
    video {
      width: 100%;
      height: r(400);
    }

    .step-wrap {
      font-size: r(30);
      margin-bottom: r(40);
      .step-title {
        margin-bottom: r(20);
      }
      .text {
        font-size: r(26);
        line-height: r(40);
        margin-bottom: r(20);
      }
      img {
        display: block;
        margin-top: r(20);
        width: 100%;
      }
      .kefu-wrap {
        width: r(400);
        margin: r(100) auto 0;
      }
    }
  }
  .next-button {
    flex-shrink: 0;
    padding: r(20) r(40) r(60) r(40);
    :deep(.van-button) {
      border-radius: 22px;
    }
  }
  .agent-wrap {
    font-size: r(26);
    line-height: r(40);
    padding: r(60) r(40);
    .title {
      font-size: r(36);
      margin-bottom: r(30);
      margin-top: r(30);
    }
    p {
      margin-bottom: r(20);
    }
    .ratio-wrap {
      margin: r(40);
      .item {
        display: flex;
        margin-bottom: r(20);
        .sub-title {
          margin-right: r(20);
          flex-shrink: 0;
        }
        .text-wrap {
          flex-wrap: wrap;
          p {
            margin-bottom: r(0);
          }
        }
      }
    }
  }
}
</style>