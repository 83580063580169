<template>
  <div class="guide-wrap">
    <!-- 选择抖音号 -->
    <DYAccount :isShowAdd="true" @newAccount="addNew" />
    <div class="process-wrap animate__animated animate__backInLeft">
      <van-steps
        :active="activeStep"
        active-color="#38f"
        active-icon="info"
        finish-icon="checked"
      >
        <van-step
          v-for="(item, index) in steps"
          :key="index"
          :class="{ big: item.isBig }"
          >{{ item.isBig ? item.label : "" }}</van-step
        >
      </van-steps>
    </div>
    <div class="content">
      <DYInfo
        v-if="
          [
            's1_new',
            's1_no_douyin_id',
            's1_douyin_id_get_code',
            's1_check_douyin_id_pending',
            's1_check_douyin_id_failed',
            's1_check_douyin_id_success',
          ].includes(stage)
        "
      />
      <PayService
        v-else-if="
          ['s2_no_huopan', 's2_order', 's2_no_address', 's3_device'].includes(
            stage
          )
        "
      />
      <FansShop
        v-else-if="['s4_fans', 's5_shop', 's6_guild_binding'].includes(stage)"
      />
      <Broadcast
        v-else-if="
          [
            's6_guild_bound',
            's7_decorating',
            's8_install_app',
            's9_setting',
            's9_room_creating',
            's9_room_created',
            's10_prepare',
            's10_finished',
            null,
          ].includes(stage)
        "
      />
    </div>
    <div class="advisor" @click="showContact">添加导师</div>
    <!-- 联系客服弹出层 -->
    <ContactCustom v-model="isShowContact" :showClose="true" />
    <div class="start-wrap animate__animated">
      <img
        src="@/assets/img/logo.png"
        alt="Logo"
        class="animated-logo animate__animated bounce-enter-active"
      />
    </div>
    <van-dialog v-model="addNewFlag" title="" :show-confirm-button="false">
      <div class="addNewButton">
        <van-button type="info" @click="addNew">添加新账号</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import DYAccount from "@/components/DYAccount/index.vue";
import DYInfo from "./Dyinfo/index.vue";
import PayService from "./PayService/index.vue";
import FansShop from "./Fans_Shop/index.vue";
import ContactCustom from "@/components/ContactCustom/index.vue";
import Broadcast from "./Broadcast/index.vue";
import { mapActions, mapMutations, mapState } from "vuex";
//开播准备的各个状态，以及对应的状态
const STEP_ENUM = {
  s1_new: 0, // 新账号, 没有看过第一个视频
  s1_no_douyin_id: 0, //未输入抖音id
  s1_douyin_id_get_code: 0, //  未验证抖音id(已填, 未验证)
  s1_check_douyin_id_pending: 0, // 待校验抖音id
  s1_check_douyin_id_failed: 0, //校验抖音id失败(需要提供失败原因)
  s1_check_douyin_id_success: 0, // 校验抖音id成功(需要提供抖音信息: 账号名,粉丝数,作品数)
  s2_no_huopan: 1, // 没有选择货盘
  s2_order: 1, //  等待下单付款
  s2_no_address: 2, //已付款, 待填收货地址
  s3_device: 2, // 了解设备
  s4_fans: 3, // 涨粉中
  s5_shop: 4, // 开橱窗中
  s6_guild_binding: 5, //绑定工会中
  s6_guild_bound: 5, //工会绑定完毕
  s7_decorating: 6, // 直播间装修
  s8_install_app: 7, // 安装软件
  s9_setting: 8, // 手机设置
  s9_room_creating: 9, //直播间搭建中
  s9_room_created: 9, // 直播间搭建完毕
  s10_prepare: 9, // 开播准备
  s10_finished: 10, // 可开播
};
export default {
  components: {
    DYAccount,
    ContactCustom,
    DYInfo,
    PayService,
    FansShop,
    Broadcast,
  },
  data() {
    return {
      isShowContact: false,
      steps: [
        { label: "信息登记", isBig: true },
        { label: "了解服务", isBig: false },
        { label: "了解设备", isBig: false },
        { label: "开通橱窗", isBig: true },
        { label: "开橱窗功能", isBig: false },
        { label: "绑定公会", isBig: false },
        { label: "主页装修", isBig: false },
        { label: "安装软件", isBig: true },
        { label: "手机设置", isBig: false },
        { label: "准备开播", isBig: true },
      ],
      activeStep: 0,
      addNewFlag: false,
      flag: false,
    };
  },
  computed: {
    ...mapState(["accountInfo", "accountList"]),
    ...mapState({
      stage: (state) => state.accountInfo && state.accountInfo.stage,
    }),
  },
  watch: {
    stage: {
      immediate: true,
      handler() {
        if (this.stage) {
          this.activeStep = STEP_ENUM[this.accountInfo.stage];
        } else if (this.stage === null) {
          this.activeStep = 10;
        }
      },
    },
    accountList: {
      immediate: true,
      handler() {
        // console.log(">>>>>1 accountlist", this.accountList);
        if (this.accountList && this.accountList.length) {
          this.addNewFlag = false;
        } else {
          this.addNewFlag = true;
        }
        // console.log(">>>>> 2accountlist", this.addNewFlag);
      },
    },
  },
  methods: {
    ...mapActions(["getUserDyAccount"]),
    ...mapMutations(["setCurrentPid"]),
    async addNew() {
      if (this.flag) return;
      this.flag = true;
      let ret = await this.$http.post(`/devices`);
      await this.getUserDyAccount();
      let account = this.accountList.find((item) => item.id == ret.id);
      let current = null;
      if (account) {
        current = account;
      }
      localStorage.setItem("XZM-ID", account.id);
      this.setCurrentPid(current);
      this.activeStep = 0;
      this.flag = false;
    },
    showContact() {
      this.isShowContact = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.guide-wrap {
  height: 100%;
  width: 100%;
  font-size: r(30);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // font-family: "FangZhengKaiTiJianTi";
  .content {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .process-wrap {
    transition: transform 0.3s ease-out;
    :deep {
      .van-step {
        position: relative;
        flex: auto;
        width: r(80);
        &.big {
          width: r(100);
          .van-step__title {
            width: r(104);
          }
          .van-step__icon {
            font-size: r(30);
          }
          .van-step__circle {
            width: r(30);
            height: r(30);
          }
        }
        &.van-step--finish {
          .van-step__title {
            color: rgb(51, 136, 255);
          }
        }
        &:last-child {
          width: r(30) !important;
        }
      }
      .van-steps--horizontal {
        padding-left: r(47);
        padding-right: r(47);
      }
      .van-step__title {
        font-size: r(26);
        width: r(60);
        margin-left: 0;
        position: absolute;
        top: r(30);
        height: r(30);
        left: 0;
        transform: translate3d(r(-40), 0, 0);
      }
      .van-step__circle {
        width: r(5);
        height: r(5);
      }
      .van-step__circle-container {
        top: 0px;
        padding: 0 r(8);
      }
      .van-step__line {
        top: 0px;
      }
    }
  }
  .advisor {
    position: absolute;
    left: 0;
    bottom: r(200);
    width: r(50);
    padding: r(10);
    border-top-right-radius: r(8);
    border-bottom-right-radius: r(8);
    border: r(2) solid #ddd;
    font-size: r(26);
    text-align: center;
    color: #fff;
    background-color: #ff9600;
  }
  .start-wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    background: #fff;
    z-index: 19990;
    animation: startAn 5s;
    opacity: 0;
    .animated-logo {
      width: r(200);
      height: r(200);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: r(-100);
      margin-top: r(-100);
    }
  }
  .addNewButton {
    margin: r(200) 0;
    text-align: center;
  }
}
.bounce-enter-active {
  animation: flip 1s 2, rubberBand 1s ease-in-out 1s;
}
@keyframes startAn {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  60% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  99% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}
</style>