<template>
  <div class="select-cate-wrap">
    <div class="title">主页装修</div>
    <div class="desc">
      修改资料，改成和<span class="huopan">【{{ huopan }}】</span>相关的内容
    </div>
    <div
      v-if="step === 2"
      class="wrap animate__animated animate__fadeIn"
      key="2-1"
    >
      <div class="sub-title">1.修改昵称</div>
      <img
        src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/modify_homepage_1.png"
        alt=""
      />
      <div class="names-wrap">
        <div class="title-wrap">
          <span>参考使用</span>
          <span class="btn" @click="fetchNames">换一批</span>
        </div>
        <div class="item-wrap">
          <div class="item" v-for="(item, index) in names" :key="index">
            <span class="name">{{ item }}</span>
            <i class="copy" @click.stop="copy(item)"></i>
          </div>
        </div>
      </div>
      <div class="sub-title">2.介绍修改</div>
      <img
        src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/modify_homepage_2.png"
        alt=""
      />
      <div class="profile-wrap">
        <div class="title-wrap">
          <span>参考使用</span>
          <span class="btn" @click="fetchProfiles">换一批</span>
        </div>
        <div class="item-wrap">
          <div class="item" v-for="(item, index) in profiles" :key="index">
            <span class="name">{{ item }}</span>
            <i class="copy" @click.stop="copy(item)"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="step === 3"
      key="2-3"
      class="wrap animate__animated animate__fadeIn"
    >
      <div class="sub-title">
        3.修改头像<span class="label">(可用手机浏览器查找)</span>
      </div>
      <img
        src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/modify_homepage_3.png"
        alt=""
      />
      <div class="sub-title">
        4.修改背景图<span class="label">(可用手机浏览器查找)</span>
      </div>
      <img
        src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/modify_homepage_4.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    step: Number,
  },
  data() {
    return {
      names: [],
      profiles: [],
    };
  },
  created() {
    this.fetchNames();
    this.fetchProfiles();
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState({
      huopan: (state) => state.accountInfo && state.accountInfo.huopan,
    }),
  },
  methods: {
    async fetchNames() {
      let ret = await this.$http.get(
        `/devices/${this.accountInfo.id}/rec-names`
      );
      this.names = ret;
    },
    async fetchProfiles() {
      let ret = await this.$http.get(
        `/devices/${this.accountInfo.id}/rec-intros`
      );
      this.profiles = ret;
    },
    copy(text) {
      this.$copyText(text).then(() => {
        this.$toast("复制成功");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.select-cate-wrap {
  .title {
    font-size: r(36);
    margin-bottom: r(20);
  }
  .desc {
    font-size: r(26);
    .huopan {
      color: #ee0a24;
    }
  }
  .wrap {
    .sub-title {
      font-size: r(26);
      margin-top: r(40);
      .label {
        margin-left: r(40);
        color: #999393;
      }
    }
    > img {
      display: block;
      margin: r(16) 0;
      width: 100%;
    }
  }
  .names-wrap {
    padding: r(0) r(10);
  }
  .title-wrap {
    margin-top: r(30);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: r(30);
    margin-bottom: r(30);
    .btn {
      color: #1989fa;
    }
  }
  .item-wrap {
    display: flex;
    flex-wrap: wrap;
    font-size: r(26);
    .item {
      display: flex;
      align-content: center;
      padding: r(30) r(20);
      width: 40%;
      justify-content: space-between;
      background: #f7f8fd;
      margin-bottom: r(20);
      &:nth-child(odd) {
        margin-right: 10%;
      }
      .name {
        flex: 1;
      }
      .copy {
        flex-shrink: 0;
        width: r(30);
        height: r(30);
        background: url(../../../assets/img/copy-icon.svg) center no-repeat;
        background-size: cover;
      }
    }
  }
  .profile-wrap {
    .item {
      width: 100%;
      margin-right: 0 !important;
      line-height: r(40);
      .copy {
        margin-left: r(10);
      }
    }
  }
}
</style>