var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-wrap"},[_c('DYAccount',{attrs:{"isShowAdd":true},on:{"newAccount":_vm.addNew}}),_c('div',{staticClass:"process-wrap animate__animated animate__backInLeft"},[_c('van-steps',{attrs:{"active":_vm.activeStep,"active-color":"#38f","active-icon":"info","finish-icon":"checked"}},_vm._l((_vm.steps),function(item,index){return _c('van-step',{key:index,class:{ big: item.isBig }},[_vm._v(_vm._s(item.isBig ? item.label : ""))])}),1)],1),_c('div',{staticClass:"content"},[(
        [
          's1_new',
          's1_no_douyin_id',
          's1_douyin_id_get_code',
          's1_check_douyin_id_pending',
          's1_check_douyin_id_failed',
          's1_check_douyin_id_success',
        ].includes(_vm.stage)
      )?_c('DYInfo'):(
        ['s2_no_huopan', 's2_order', 's2_no_address', 's3_device'].includes(
          _vm.stage
        )
      )?_c('PayService'):(['s4_fans', 's5_shop', 's6_guild_binding'].includes(_vm.stage))?_c('FansShop'):(
        [
          's6_guild_bound',
          's7_decorating',
          's8_install_app',
          's9_setting',
          's9_room_creating',
          's9_room_created',
          's10_prepare',
          's10_finished',
          null,
        ].includes(_vm.stage)
      )?_c('Broadcast'):_vm._e()],1),_c('div',{staticClass:"advisor",on:{"click":_vm.showContact}},[_vm._v("添加导师")]),_c('ContactCustom',{attrs:{"showClose":true},model:{value:(_vm.isShowContact),callback:function ($$v) {_vm.isShowContact=$$v},expression:"isShowContact"}}),_vm._m(0),_c('van-dialog',{attrs:{"title":"","show-confirm-button":false},model:{value:(_vm.addNewFlag),callback:function ($$v) {_vm.addNewFlag=$$v},expression:"addNewFlag"}},[_c('div',{staticClass:"addNewButton"},[_c('van-button',{attrs:{"type":"info"},on:{"click":_vm.addNew}},[_vm._v("添加新账号")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start-wrap animate__animated"},[_c('img',{staticClass:"animated-logo animate__animated bounce-enter-active",attrs:{"src":require("@/assets/img/logo.png"),"alt":"Logo"}})])
}]

export { render, staticRenderFns }