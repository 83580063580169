<template>
  <div class="download-wrap">
    <div class="title">安装小竹猫</div>
    <div class="desc">使用【直播的手机】浏览器，扫码下载</div>
    <div class="qrcode" v-if="config.apk">
      <VueQRCodeComponent :text="config.apk" />
    </div>
    <div class="sub-title">
      下载地址
      <i class="icon" @click="copy"></i>
    </div>
    <div class="url-tip">{{ config.apk || "" }}</div>
  </div>
</template>
<script>
import VueQRCodeComponent from "vue-qrcode-component";
export default {
  data() {
    return {
      qrColor: { dark: "#000000ff", light: "#ffffffff" },
      config: {},
    };
  },
  components: {
    VueQRCodeComponent,
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      let ret = await this.$http.get(`/last-setting`);
      this.config = ret;
      this.config.apk = ret.apk.replace("http:", "https:");
    },
    copy() {
      this.$copyText(this.config.apk).then(() => {
        this.$toast("复制成功");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.download-wrap {
  .title {
    font-size: r(36);
    margin-bottom: r(20);
  }
  .desc {
    font-size: r(26);
  }
  .qrcode {
    margin-top: r(80);
    text-align: center;
    > div {
      margin: 0 auto;
      width: 256px;
      height: 256px;
      overflow: hidden;
      padding: r(8);
      background: #fff;
      border-radius: r(8);
      img {
        width: 100%;
      }
    }
  }
  .sub-title {
    font-size: r(30);
    margin-top: r(40);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .url-tip {
    font-size: r(26);
    margin-top: r(30);
    word-break: break-all;
    line-height: r(30);
    color: rgb(51, 136, 255);
  }
  .icon {
    margin-left: r(10);
    width: r(30);
    height: r(30);
    background: url(../../../assets/img/copy-icon.svg) center no-repeat;
    background-size: cover;
  }
}
</style>