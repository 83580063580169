<template>
  <div class="dyinfo-wrap">
    <div class="content">
      <!--抖音号验证-->
      <div
        class="video-wrap introduction animate__animated animate__backInDown"
        v-if="stepDetail === 1"
      >
        <div class="title">什么是“云带货讲解师”？</div>
        <video
          controls
          controlslist="nodownload"
          src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/introduction.mp4"
          poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/introduction_poster.png"
        ></video>
      </div>
      <div
        class="step-wrap step-middle animate__animated animate__fadeInLeftBig"
        v-else-if="stepDetail === 2"
      >
        <div class="title">抖音号</div>
        <van-field
          class="title-input"
          v-model.trim="dyNo"
          placeholder="请输入你的抖音号"
          @focus="showTip"
        />
        <div class="tip-label" v-if="showTipFlag">
          建议用新注册的抖音号，或者自用号
        </div>
        <div class="tip-wrap">
          <div class="tip-title">如何找到自己的抖音号？</div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/dyno.png"
            alt="抖音号"
          />
        </div>
      </div>
      <div
        class="step-wrap step-middle animate__animated animate__fadeIn"
        v-else-if="stepDetail === 3"
      >
        <div class="title">账号验证</div>
        <div class="title-desc">复制账号验证码，粘贴到【简介】后，提交验证</div>
        <div class="input-wrap">
          <van-field
            class="title-input"
            v-model.trim="dyCode"
            placeholder="验证码"
            disabled
          />
          <div id="copy-btn" :data-link="dyCode" @click="copy">
            <img src="@/assets/img/copy-icon.svg" alt="" />
          </div>
        </div>
        <div class="tip-wrap code">
          <div class="tip-title">复制账号验证码，粘贴到【简介】</div>
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/user_profile_guide.png"
            alt="抖音号"
          />
          <img
            src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/user_profile.png"
            alt="抖音号"
          />
        </div>
      </div>
    </div>
    <div class="next-button animate__animated animate__backInUp">
      <van-button type="info" @click="verifyCode" block v-if="stepDetail === 3"
        >提交验证</van-button
      >
      <van-button v-else type="info" @click="next" block :disabled="disableNext"
        >下一步</van-button
      >
    </div>
    <!--账号验证-->
    <van-dialog v-model="showCheckFlag" title="" :showConfirmButton="false">
      <div
        class="check-dialog-wrap"
        v-if="!verifyRet || verifyRet.stage === 's1_check_douyin_id_pending'"
      >
        <div class="tip">
          <div>账号验证中，请稍等...</div>
          <div class="loading">
            <van-loading
              type="spinner"
              color="#000"
              size="30"
              vertical
            ></van-loading>
          </div>
        </div>
      </div>
      <div
        class="check-dialog-wrap"
        v-else-if="verifyRet && verifyRet.stage === 's1_check_douyin_id_failed'"
      >
        <div class="check-content">
          <div class="title">验证失败</div>
          <div class="tip error">{{ verifyRet ? verifyRet.message : "" }}</div>
        </div>
        <div class="button-wrap">
          <van-button type="info" size="small" @click="cancelVerify"
            >返回</van-button
          >
        </div>
      </div>
      <div
        class="check-dialog-wrap"
        v-else-if="
          verifyRet && verifyRet.stage === 's1_check_douyin_id_success'
        "
      >
        <div class="check-content">
          <div class="title">验证通过</div>
          <div class="item">
            <div class="sub-title">抖音号</div>
            <div class="wrap">
              <span>{{ dyNo }}</span>
              <van-button size="mini" @click="cancelVerify">修改</van-button>
            </div>
          </div>
          <div class="item">
            <div class="sub-title">账号信息</div>
            <div class="wrap">
              <div>{{ verifyRet.info.name }}</div>
              <div class="extra">
                (粉丝数：{{ verifyRet.info.fansCnt }}；作品：{{
                  verifyRet.info.videosCnt
                }})
              </div>
            </div>
          </div>
        </div>
        <div class="button-wrap">
          <van-button type="info" size="small" @click="toNextProcess"
            >下一步</van-button
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import ClipboardJS from "clipboard";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      dyNo: null, //抖音号
      stepDetail: 1, //每个阶段的里的详细步骤
      showTipFlag: false,
      dyCode: "",
      showCheckFlag: false, //校验激活码
      verifyRet: null,
    };
  },
  computed: {
    ...mapState({
      stage: (state) => state.accountInfo && state.accountInfo.stage,
      accountInfo: (state) => state.accountInfo,
    }),
    disableNext() {
      if (this.stepDetail === 2 && !this.dyNo) {
        return true;
      }
      return false;
    },
  },
  watch: {
    stage: {
      immediate: true,
      handler() {
        switch (this.stage) {
          case "s1_new":
            this.stepDetail = 1;
            if (this.accountInfo.extra && !this.dyNo) {
              this.dyNo = this.accountInfo.extra.douyinid || null;
            }
            break;
          case "s1_no_douyin_id":
            if (this.accountInfo.extra && !this.dyNo) {
              this.dyNo = this.accountInfo.extra.douyinid || null;
            }
            if (this.accountInfo.extra && !this.dyCode) {
              this.dyCode = this.accountInfo.extra.checkCode || null;
            }
            this.stepDetail = 2;
            break;
          case "s1_douyin_id_get_code":
            this.stepDetail = 3;
            if (this.accountInfo.extra && !this.dyNo) {
              this.dyNo = this.accountInfo.extra.douyinid || null;
            }
            if (this.accountInfo.extra && !this.dyCode) {
              this.dyCode = this.accountInfo.extra.checkCode || null;
            }
            break;
          case "s1_check_douyin_id_pending":
            this.showCheckFlag = true;
            this.stepDetail = 3;
            this.dyNo = this.accountInfo.extra.douyinid;
            this.dyCode = this.accountInfo.extra.checkCode;
            this.verifyRet = {
              stage: this.accountInfo.stage,
              message: this.accountInfo.extra.failedMessage,
              info: {
                name: this.accountInfo.extra.douyinName,
                fansCnt: this.accountInfo.extra.fansCnt,
                videosCnt: this.accountInfo.extra.videosCnt,
              },
            };
            this.checkVerify();
            break;
          case "s1_check_douyin_id_failed":
          case "s1_check_douyin_id_success":
            this.showCheckFlag = true;
            this.stepDetail = 3;
            this.dyNo = this.accountInfo.extra.douyinid;
            this.dyCode = this.accountInfo.extra.checkCode;
            this.verifyRet = {
              stage: this.accountInfo.stage,
              message: this.accountInfo.extra.failedMessage,
              info: {
                name: this.accountInfo.extra.douyinName,
                fansCnt: this.accountInfo.extra.fansCnt,
                videosCnt: this.accountInfo.extra.videosCnt,
              },
            };
            break;
          default:
            return;
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setCurrentPid"]),
    showTip() {
      this.showTipFlag = true;
    },
    async next() {
      this.showTipFlag = false;
      //用户输入抖音号
      if (this.stepDetail === 2) {
        this.fetchCode();
      }
      this.stepDetail++;
      if (this.stepDetail === 2) {
        this.setCurrentPid(
          Object.assign({}, this.accountInfo, { stage: "s1_no_douyin_id" })
        );
      } else if (this.stepDetail === 3) {
        this.setCurrentPid(
          Object.assign({}, this.accountInfo, {
            stage: "s1_douyin_id_get_code",
          })
        );
      }
    },
    async fetchCode() {
      let oldDyno = this.accountInfo.extra
        ? this.accountInfo.extra.douyinid
        : "";
      if (oldDyno === this.dyNo && this.accountInfo.extra.checkCode) {
        this.dyCode = this.accountInfo.extra.checkCode;
        return;
      }
      let ret = await this.$http.put(
        `/devices/${this.accountInfo.id}/douyinid`,
        {
          douyinid: this.dyNo,
        }
      );
      if (ret && ret.extra && ret.extra.checkCode) {
        this.dyCode = ret.extra.checkCode;
      }
    },
    async verifyCode() {
      await this.$http.post(`/devices/${this.accountInfo.id}/check-douyinid`);
      this.showCheckFlag = true;
      this.checkVerify();
    },
    async checkVerify() {
      clearTimeout(this.checkInterval);
      try {
        let ret = await this.$http.get(`/devices/${this.accountInfo.id}`);
        //校验失败
        if (ret.stage === "s1_check_douyin_id_failed") {
          this.verifyRet = {
            stage: ret.stage,
            message: ret.extra.failedMessage,
          };
          return;
        } else if (ret.stage === "s1_check_douyin_id_success") {
          //校验成功
          this.verifyRet = {
            stage: ret.stage,
            info: {
              name: ret.extra.douyinName,
              fansCnt: ret.extra.fansCnt,
              videosCnt: ret.extra.videosCnt,
            },
          };
          this.setCurrentPid(ret);
          return;
        }
      } catch (e) {
        console.log(">>>>check error", e);
      }
      if (!this.showCheckFlag) return;
      this.checkInterval = setTimeout(() => {
        this.checkVerify();
      }, 5e3);
    },
    cancelVerify() {
      this.showCheckFlag = false;
      clearTimeout(this.checkInterval);
      this.verifyRet = null;
      this.stepDetail = 2;
    },
    copy() {
      var clp = new ClipboardJS("#copy-btn", {
        text: function (trigger) {
          return trigger.getAttribute("data-link");
        },
      });
      clp.on("success", () => {
        this.$toast("复制成功");
        clp.destroy();
      });
    },
    async toNextProcess() {
      await this.$http.put(
        `/devices/${this.accountInfo.id}/stage/s2_no_huopan`
      );
      this.setCurrentPid(
        Object.assign({}, this.accountInfo, { stage: "s2_no_huopan" })
      );
    },
  },
  beforeDestroy() {
    this.checkVerify && clearTimeout(this.checkVerify);
  },
};
</script>
<style lang="scss" scoped>
.dyinfo-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;
    .step-wrap {
      padding: r(30);
      box-sizing: border-box;
      width: 100%;
      &.step-middle {
        padding-top: r(80);
        padding-left: r(60);
        padding-right: r(60);
      }
      .title {
        font-size: r(40);
        margin-bottom: r(50);
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .tip-wrap {
          font-size: r(26);
          margin-left: r(20);
          .text {
            color: #1989fa;
          }
        }
      }
      .title-desc {
        font-size: r(26);
        margin-top: r(-20);
        margin-bottom: r(50);
        color: #aaa;
      }
      .input-wrap {
        position: relative;
        #copy-btn {
          position: absolute;
          right: r(10);
          bottom: 10px;
          img {
            width: r(40);
          }
        }
        .title-input {
          padding-right: r(40);
        }
      }
      .title-input {
        padding-left: 0;
        padding-right: 0;
        font-size: r(30);
        border-bottom: r(1) solid #ddd;
        box-sizing: border-box;
        :deep(.van-field__control:disabled) {
          color: #000;
          -webkit-text-fill-color: #000;
        }
      }
      .tip-label {
        margin-top: r(20);
        font-size: r(20);
        color: #ee0a24;
      }
      .tip-wrap {
        margin-top: r(200);
        padding: 0 r(20) 0;
        &.code {
          margin-top: r(40);
        }
        .tip-title {
          font-size: r(30);
          margin-bottom: r(40);
        }
        img {
          display: block;
          width: 100%;
          margin-bottom: r(30);
        }
      }
    }
    .video-wrap {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      video {
        width: 100%;
        display: block;
      }
      .title {
        margin-bottom: r(40);
        font-size: r(40);
        text-align: center;
        font-family: "FangZhengKaiTiJianTi";
      }
    }
  }
  .image-wrap {
    width: 80%;
    box-sizing: border-box;
    background: #fff;
    padding: r(100) r(60);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    .title {
      font-size: r(40);
      margin-bottom: r(60);
    }
    .label {
      font-size: r(26);
      margin-bottom: r(60);
    }
    img {
      display: block;
      width: 100%;
      margin-bottom: r(20);
    }
  }
  .next-button {
    flex-shrink: 0;
    padding: r(20) r(40) r(60) r(40);
    :deep(.van-button) {
      border-radius: 22px;
    }
  }
  .check-dialog-wrap {
    padding: r(40);
    padding-top: r(80);
    height: r(600);
    font-size: r(30);
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    .title {
      font-size: r(40);
      margin-bottom: r(40);
    }
    .tip {
      &.error {
        color: #ee0a24;
      }
    }
    .loading {
      text-align: center;
      margin-top: r(160);
    }
    .button-wrap {
      text-align: center;
      :deep(.van-button) {
        border-radius: 16px;
        padding-left: r(50);
        padding-right: r(50);
      }
    }
    .check-content {
      .item {
        padding-bottom: r(10);
        border-bottom: 1px solid #aaa;
        margin-bottom: r(40);
        .sub-title {
          font-size: r(34);
          margin-bottom: r(26);
        }
        .wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .extra {
            color: #aaa;
            font-size: r(26);
          }
        }
      }
    }
  }
}
</style>