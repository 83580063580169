<template>
  <div class="broadcast-wrap">
    <div
      class="content animate__animated"
      :class="stepDetail < 9 ? 'animate__fadeInLeftBig' : 'animate__bounceIn'"
    >
      <div v-if="stepDetail === 1" key="1">
        <div class="playground">
          <img src="@/assets/img/color-success.svg" alt="" class="icon" />
          <div class="text-wrap">
            <div class="text-1">公会已绑定</div>
          </div>
        </div>
      </div>
      <!--选择分类-->
      <SelectCate
        v-else-if="stepDetail === 2 || stepDetail === 3"
        :step="stepDetail"
        class="animate__animated animate__fadeIn"
        key="2"
      />
      <!--下载-->
      <Download
        v-else-if="stepDetail === 4"
        key="3"
        class="animate__animated animate__fadeIn"
      />
      <!--直播手机设置-->
      <div
        v-else-if="stepDetail === 5"
        key="4"
        class="animate__animated animate__fadeIn"
      >
        <div class="wrap">
          <div class="wrap-content">
            <div class="title">直播手机设置</div>
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item name="1">
                <template #title>
                  <div class="title-wrap" @click.stop="">
                    <van-checkbox v-model="step1"
                      >1.关闭抖音悬浮窗</van-checkbox
                    >
                  </div>
                </template>
                <video
                  controls
                  controlslist="nodownload"
                  src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/close_pop_up.mp4"
                  poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/close_pop_up_poster.png"
                ></video>
              </van-collapse-item>
              <van-collapse-item name="2">
                <template #title>
                  <div class="title-wrap" @click.stop="">
                    <van-checkbox v-model="step2"
                      >2.锁定抖音/小竹猫</van-checkbox
                    >
                  </div>
                </template>
                <video
                  controls
                  controlslist="nodownload"
                  src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/lock_software.mp4"
                  poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/lock_software_poster.jpg"
                ></video>
              </van-collapse-item>
              <van-collapse-item name="3">
                <template #title>
                  <div class="title-wrap" @click.stop="">
                    <van-checkbox v-model="step3"
                      >3.关闭省电模式/电量优化</van-checkbox
                    >
                  </div>
                </template>
                <video
                  controls
                  controlslist="nodownload"
                  src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/turn_off_power_save_mode.mp4"
                  poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/turn_off_power_save_mode_poster.jpg"
                ></video>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div class="tip-content">
            <div class="tip-title">注意事项</div>
            <div class="text">1. 完成后，请勾选内容前的按钮</div>
            <div class="text">2. 这3步可以提高开播后的稳定性</div>
          </div>
        </div>
      </div>
      <template v-else-if="stepDetail === 6">
        <div class="playground animate__animated animate__fadeIn" key="6">
          <img src="@/assets/img/clock.svg" alt="" class="icon" />
          <div class="text-wrap">
            <div class="text-1">直播间搭建中</div>
            <div class="text-tip">搭建完成进入下一步</div>
          </div>
        </div>
      </template>
      <template v-else-if="stepDetail === 7">
        <div class="playground animate__animated animate__fadeIn" key="7">
          <img src="@/assets/img/color-success.svg" alt="" class="icon" />
          <div class="text-wrap">
            <div class="text-1">直播间搭建完成</div>
          </div>
        </div>
      </template>
      <template v-else-if="stepDetail === 8">
        <div class="wrap animate__animated animate__fadeIn" key="8">
          <div class="wrap-content">
            <div class="title">准备开播</div>
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item name="1">
                <template #title>
                  <div class="title-wrap" @click.stop="">
                    <van-checkbox v-model="step4">1.设置直播内容</van-checkbox>
                  </div>
                </template>
                <video
                  controls
                  controlslist="nodownload"
                  src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_live_room.mp4"
                  poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/set_live_room_poster.jpg"
                ></video>
                <div class="text-item red">注意事项</div>
                <div class="text-item">
                  1. 教程里，<span class="red">【直播间封面】</span>要选择<span
                    class="red"
                    >【{{ huopan }}】</span
                  >相关的图片
                </div>
                <div class="text-item">
                  2. 可以从电脑、手机浏览器，查找下载图片，替换使用
                </div>
                <div class="text-item">3. 高清、大图、无水印</div>
              </van-collapse-item>
              <van-collapse-item name="2">
                <template #title>
                  <div class="title-wrap" @click.stop="">
                    <van-checkbox v-model="step5"
                      >2. 首次使用小竹猫教程</van-checkbox
                    >
                  </div>
                </template>
                <video
                  controls
                  controlslist="nodownload"
                  src="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/start_live_broadcast.mp4"
                  poster="https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/preparation/start_live_broadcast_poster.jpg"
                ></video>
              </van-collapse-item>
              <van-collapse-item name="3">
                <template #title>
                  <div class="title-wrap" @click.stop="">
                    <van-checkbox v-model="step6">3.开播后检查</van-checkbox>
                  </div>
                </template>
                <div class="text-item">
                  1.检查开播页面，左上角，是否没有悬浮窗
                </div>
                <div class="text-item">2.检查声音是否正常</div>
                <div class="text-item">3.检查画面（人像、背景等）是否正常</div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div class="tip-content">
            <div class="tip-title">注意事项</div>
            <div class="text">1. 完成后，请勾选内容前的按钮</div>
            <div class="text">2. 这3步很重要</div>
          </div>
        </div>
      </template>
      <div v-else key="9">
        <div class="playground">
          <img src="@/assets/img/color-success.svg" alt="" class="icon" />
          <div class="text-wrap">
            <div class="text-1">恭喜完成开播准备</div>
            <div class="text-tip">可以开启直播之旅了</div>
          </div>
        </div>
        <div class="kefu-wrap">
          <img src="@/assets/img/kefu.png" alt="" class="kefu" />
          <div class="tip">遇到任何问题 都可以联系导师</div>
        </div>
      </div>
    </div>
    <div class="next-button" v-if="[1, 2, 5, 7, 8].indexOf(stepDetail) > -1">
      <van-button type="info" block @click="next" :disabled="disableButton"
        >下一步</van-button
      >
    </div>
    <div class="prevButton" v-else-if="stepDetail < 9">
      <van-button @click="prev">上一步</van-button>
      <van-button
        type="info"
        @click="next"
        :disabled="disableButton"
        v-if="stepDetail !== 6"
        >我已完成下一步</van-button
      >
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import SelectCate from "./selectCate.vue";
import Download from "./download.vue";
export default {
  data() {
    return {
      stepDetail: 1,
      fCnt: 0,
      vCnt: 0,
      activeName: 0,
      showTipFlag: false,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
    };
  },
  components: {
    SelectCate,
    Download,
  },
  computed: {
    ...mapState({
      stage: (state) => state.accountInfo && state.accountInfo.stage,
      accountInfo: (state) => state.accountInfo,
      fans: (state) => {
        if (state.accountInfo && state.accountInfo.extra) {
          return state.accountInfo.extra.fansCnt;
        } else {
          return 0;
        }
      },
      videosCnt: (state) => {
        if (state.accountInfo && state.accountInfo.extra) {
          return state.accountInfo.extra.videosCnt;
        } else {
          return 0;
        }
      },
      disableButton() {
        if (
          this.stepDetail === 5 &&
          (!this.step1 || !this.step2 || !this.step3)
        ) {
          return true;
        } else if (
          this.stepDetail === 8 &&
          (!this.step4 || !this.step5 || !this.step6)
        ) {
          return true;
        } else {
          return false;
        }
      },
      huopan: (state) => {
        return state.accountInfo.huopan;
      },
    }),
    fanText() {
      let str = "";
      str = Math.floor(this.fans / 10);
      return str + "%";
    },
    videoText() {
      let str = "";
      str = Math.floor(this.videosCnt * 10);
      return str + "%";
    },
  },
  watch: {
    stage: {
      immediate: true,
      handler() {
        switch (this.stage) {
          case "s6_guild_bound":
            this.stepDetail = 1;
            break;
          case "s7_decorating":
            if (this.stepDetail === 3) return;
            this.stepDetail = 2;
            break;
          case "s8_install_app":
            this.stepDetail = 4;
            break;
          case "s9_setting":
            this.stepDetail = 5;
            break;
          case "s9_room_creating":
            this.stepDetail = 6;
            break;
          case "s9_room_created":
            this.stepDetail = 7;
            break;
          case "s10_prepare":
            this.stepDetail = 8;
            break;
          case "s10_finished":
            this.stepDetail = 9;
            break;
          default:
            this.stepDetail = 9;
            return;
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setCurrentPid"]),
    async next() {
      let stage = this.stage;
      switch (this.stage) {
        case "s6_guild_bound":
          stage = "s7_decorating";
          break;
        case "s7_decorating":
          if (this.stepDetail === 2) {
            this.stepDetail = 3;
          } else {
            stage = "s8_install_app";
          }
          break;
        case "s8_install_app":
          await this.$http.put(
            `/devices/${this.accountInfo.id}/stage/s9_setting`
          );
          stage = "s9_setting";
          break;
        case "s9_setting":
          stage = "s9_room_creating";
          break;
        case "s9_room_creating":
          stage = "s9_room_created";
          break;
        case "s9_room_created":
          stage = "s10_prepare";
          break;
        case "s10_prepare":
          await this.$http.put(
            `/devices/${this.accountInfo.id}/stage/s10_finished`
          );
          stage = "s10_finished";
          break;
        default:
          return;
      }
      if (stage !== this.stage) {
        this.setCurrentPid(Object.assign({}, this.accountInfo, { stage }));
      }
    },
    showTip() {
      this.showTipFlag = true;
    },
    prev() {
      let stage = this.stage;
      switch (this.stage) {
        case "s6_guild_bound":
          stage = "s7_decorating";
          break;
        case "s7_decorating":
          if (this.stepDetail === 2) {
            stage = "s6_guild_bound";
          } else {
            this.stepDetail = 2;
          }
          break;
        case "s8_install_app":
          stage = "s7_decorating";
          this.stepDetail = 3;
          break;
        case "s9_setting":
          stage = "s8_install_app";
          break;
        case "s9_room_creating":
          stage = "s9_setting";
          break;
        default:
          return;
      }
      if (stage !== this.stage) {
        this.setCurrentPid(Object.assign({}, this.accountInfo, { stage }));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.broadcast-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .content {
    width: 100%;
    font-size: r(26);
    padding-top: r(40);
    padding-left: r(60);
    padding-right: r(60);
    overflow-y: auto;
    flex-shrink: 0;
    flex: 1;
    .title {
      font-size: r(40);
      margin-bottom: r(40);
    }
    .playground {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: r(100);
      .icon {
        width: r(100);
        height: r(100);
        margin-right: r(20);
      }
      .text-wrap {
        font-size: r(40);
        .text-tip {
          font-size: r(26);
          color: #606266;
          margin-top: r(20);
        }
      }
    }
    .wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .tip-content {
      font-size: r(26);
      line-height: r(40);
      flex-shrink: 0;
      .tip-title {
        color: #ee0a24;
        margin-bottom: r(10);
      }
      .text {
        margin-bottom: r(10);
      }
    }
    .text-item {
      font-size: r(30);
      line-height: r(50);
      color: #000;
      margin-bottom: r(20);
    }
    .red {
      color: #ee0a24;
    }
    .kefu-wrap {
      width: r(400);
      margin: r(100) auto 0;
      img {
        width: 100%;
        margin-bottom: r(40);
      }
      .tip {
        font-size: r(30);
        color: #000;
        text-align: center;
      }
    }
  }
  .next-button {
    flex-shrink: 0;
    padding: r(20) r(40) r(60) r(40);
    :deep(.van-button) {
      border-radius: 22px;
    }
  }
  .prevButton {
    flex-shrink: 0;
    padding: r(20) r(40) r(60) r(40);
    display: flex;
    align-items: center;

    :deep(.van-button) {
      border-radius: 22px;
      flex-shrink: 0;
      &:first-child {
        padding: r(0) r(60);
      }
      &:last-child {
        flex: 1;
        margin-left: r(60);
      }
    }
  }
  .title-wrap {
    display: inline-block;
  }
  video {
    width: 100%;
    height: r(400);
    margin-bottom: r(40);
  }
}
</style>